<template>
	<div id="deploy">
		<index>
			<el-breadcrumb>
				<el-breadcrumb-item>教务</el-breadcrumb-item>
				<el-breadcrumb-item to="/paike_index">课程管理</el-breadcrumb-item>
				<el-breadcrumb-item><a style="color: #000000;">排课</a></el-breadcrumb-item>
			</el-breadcrumb>
			<div class="u-f-item u-f-jsb caozuo">
				<div>{{title}}</div>
				<div class="u-f-item">
					<el-button plain size="small" style="width: 80px;margin-right: 15px;" @click="importVisible=true">导入课表</el-button>
					<el-button plain size="small" style="width: 80px;margin-right: 15px;" @click="exports6">导出</el-button>
					<!-- <el-dropdown>
						<el-button plain size="small" style="width: 80px;margin-right: 15px;">
							导出
							<i class="el-icon-arrow-down el-icon--right"></i>
						</el-button>
						<el-dropdown-menu slot="dropdown">
							<el-dropdown-item>班级课表导出</el-dropdown-item>
							<el-dropdown-item>教师课表导出</el-dropdown-item>
							<el-dropdown-item>年级总表导出</el-dropdown-item>
							<el-dropdown-item>总表导出</el-dropdown-item>
							<el-dropdown-item style="border-top: 1px solid #EEEEEE;">导出设置</el-dropdown-item>
						</el-dropdown-menu>
					</el-dropdown> -->
				</div>
			</div>
			<el-row style="margin-top: 20px;background-color: #FFFFFF;">
				<el-col :span="20" style="border-right: 1px dashed #EEEEEE;padding-bottom: 20px;">
					<div class="u-f-item u-f-jsb" style="padding: 10px 20px;">
						<div class="u-f-item">
							<div>{{title}}</div>
							<!-- <div class="u-f-item suoding">
								<div class="iconfont icon-kaisuo suo"></div>
								<div class="suo_muen">
									锁定课表
									<i class="iconfont icon-xiala1"></i>
								</div>
							</div> -->
						</div>
						<div class="u-f-item">
							<el-button type="primary" plain size="small" @click="save">保存</el-button>
							<!-- <el-button type="primary" size="small" @click="save(1)">使用</el-button> -->
						</div>
					</div>
					<!-- 课表 -->
					<el-row :gutter="20" style="padding: 0 20px;">
						<div style="height: 70vh;overflow-y: auto;">
							<blockquote v-for="(items, index) in info" :key="index" style="margin: 0">
								<el-col :span="12" style="margin-bottom: 20px;">
									<div class="u-f-item table-head">
										<span class="class_name u-f-justify">{{items.name}}</span>
										<span class="week_name u-f-justify" v-for="(it, ind) in attend_day" :key="ind">{{it}}</span>
									</div>
									<div class="u-f-item table-item" v-for="(item,i) in items.list" :key="i">
										<span class="time_name u-f-justify">{{item.node}}</span>
										<div class="cell u-f-justify u-f-column" v-for="(str,s) in item.list" :key="s" @click="letin(index, i, s)">
											<blockquote style="margin:0">
												<div class="normal_cell" v-if="str.teacher_name||str.discipline_name">
													<p class="cell_subject">{{str.discipline_name}}</p>
													<p class="cell_teacher">{{str.teacher_name}}</p>
													<!-- <span @click.stop="del(index, i, s)" class="iconfont icon-cha" style="position: absolute;right: 0;top:0;color: red;font-size: 12px;"></span> -->
												</div>
												<div class="" v-else></div>
											</blockquote>
										</div>
									</div>
								</el-col>
							</blockquote>
						</div>
					</el-row>
				</el-col>
				<el-col :span="4">
					<div class="right_content">
						<el-row>
							<el-col :span="24" style="padding: 20px 0 0 0;">
								<div class="right-item">
									<!-- <el-input placeholder="请输入名字搜索" prefix-icon="el-icon-search" v-model="input1" size="mini"></el-input> -->
									<div style="height: 72vh;overflow-y: auto;">
										<div class="u-f-item right-item-title">
											<div class="line"></div>
											<div class="font_blod">学科</div>
											<i class="el-icon-caret-top"></i>
										</div>
										<div class="subject-list">
											<div class="subject-item" @click="subjectTap(index)" :class="subjectIndex==index?'active':''" v-for="(item,index) in subjectList" :key="index">{{item.discipline_name}}</div>
										</div>
										<div class="teacher-list" v-if="subjectList[subjectIndex]">
											<div class="u-f-item teacher-item u-f-jsb" @click="subTeacherTap(index)" :class="SubjectTecherIndex==index?'active':''"  v-for="(item,index) in subjectList[subjectIndex].teacher" :key="index">
												<div class="u-f-item">
													<span class="iconfont icon-yuandian1"></span>
													<span class="teacher-name">{{item.teacher_name}}</span>
													<span class="subject-name">{{subjectList[subjectIndex].discipline_name}}</span>
												</div>
												<div class="num"></div>
												<!-- 0/{{item.week_class}} -->
											</div>
										</div>
										<div class="u-f-item right-item-title">
											<div class="line"></div>
											<div class="font_blod">其它课程</div>
											<i class="el-icon-caret-top"></i>
										</div>
										<div class="subject-list">
											<div class="subject-item" @click="propertyTap(index)" :class="propertyIndex==index?'active':''" v-for="(item,index) in property" :key="index">{{item.discipline_name}}</div>
										</div>
									</div>
								</div>
							</el-col>
							<!-- <el-col :span="5">
								<div class="u-f-justify u-f-column controller_item">
									<div class="iconfont icon-paike"></div>
									<div>智能排课</div>
								</div>
								<div class="u-f-justify u-f-column controller_item">
									<div class="iconfont icon-chehui"></div>
									<div>撤回</div>
								</div>
								<div class="u-f-justify u-f-column controller_item">
									<div class="iconfont icon-lishijilu"></div>
									<div>历史记录</div>
								</div>
								<div class="u-f-justify u-f-column controller_item">
									<div class="iconfont icon-beizhu"></div>
									<div>备注</div>
								</div>
								<div class="u-f-justify u-f-column controller_item">
									<div class="iconfont icon-dianliang"></div>
									<div>点亮学科</div>
								</div>
								<div class="u-f-justify u-f-column controller_item">
									<div class="iconfont icon-linshilajiaodian"></div>
									<div>临时区</div>
								</div>
								<div class="u-f-justify u-f-column controller_item">
									<div class="iconfont icon-tongzhi"></div>
									<div>通知变动</div>
								</div>
								<div class="u-f-justify u-f-column controller_item">
									<div class="iconfont icon-fenlei"></div>
									<div>切换总表</div>
								</div>
								<div class="u-f-justify u-f-column controller_item">
									<div class="iconfont icon-guize"></div>
									<div>调整规则</div>
								</div>
								<div class="u-f-justify u-f-column controller_item">
									<div class="iconfont icon-kebiao-01"></div>
									<div>切换课表</div>
								</div>
							</el-col> -->
						</el-row>
					</div>
				</el-col>
			</el-row>
			
			<el-dialog title="导入课表" :visible.sync="importVisible" width="500px" :before-close="handleClose">
				<div class="edit-box">
					<div class="u-f-item input-box">
						<div class="input-lebal">
							<span>*</span>
							上传文件
						</div>
						<el-input v-model="file.name" disabled placeholder="请选择要上传的文件" style="width: 60%;margin-right:10px"></el-input>
						<el-button size="middle" style="position: relative;"><input ref="filElem" type="file" class="upload-file" @change="getFile">选择文件</el-button>
					</div>
				</div>
				<span slot="footer" class="dialog-footer u-f-item u-f-jsb">
					<el-button @click="closeimportVisible" size="small">取 消</el-button>
					<el-button type="primary" size="small" @click="submitimport">确 定</el-button>
				</span>
			</el-dialog>
		</index>
	</div>
</template>

<script>
import index from '@/components/common/index.vue';
export default {
	components: { index },
	data() {
		return {
			input1: '',
			info: [],
			id:'',
			title:'',
			classList:[],
			classIndex:0,
			TecherList:[],
			job_id:'',
			subjectList:'',
			subjectIndex:0,
			SubjectTecherList:[],
			SubjectTecherIndex:-1,
			TecherIndex:-1,
			attend_day: [],
			subList: [],
			property:[],
			propertyIndex:-1,
			importVisible:false,
			file:'',
			fileUrl:''
		};
	},
	mounted(){
		this.id = this.$route.query.id;
		this.title = this.$route.query.title;
		this.initData()
	},
	methods: {
		handleClose(done){
			done()
		},
		closeimportVisible(){
			this.file = '';
		},
		getFile(e){
			let myfile = this.$refs.filElem;
			let files = myfile.files;
			let file = files[0];
			var formData = new FormData();
			formData.append("file", file);
			this.$api.setting.uploadFile(formData).then(res=>{
				if (res.data.code == 1) {
					this.fileUrl = res.data.data.url;
					if(this.$refs.filElem.files.length){
						this.file = this.$refs.filElem.files[0]
					}
				} else {
					this.$message.error(res.data.msg);
				}
			})
		},
		submitimport(){
			this.$api.setting.deployimport({
				id:this.id,
				file:this.fileUrl
			}).then(res=>{
				if(res.data.code==1){
					this.$message.success('导入成功')
					this.initData()
				}else{
					this.$message.error(res.data.msg);	
				}
			})
			
		},
		exports6(){
			this.$api.setting.deployExport({
				id:this.id
			}).then(res=>{
				if(res.data.code==1){
					this.$message.success('导出成功')
					location.href = res.data.data;
				}
			})
		},
		initData(){
			this.$api.paike.getCourseConfig({id:this.id}).then(res => {
				if (res.data.code == 1) {
					let info = res.data.data.data;
					this.attend_day = res.data.data.attend_day;
					this.info = info;
					this.subjectList = res.data.data.config;
					this.property = res.data.data.course_attribute;
				} else {
					this.$message.error(res.data.msg);
					
				}
			});
		},
		subTeacherTap(index){
			this.SubjectTecherIndex = index;
		},
		propertyTap(index){
			this.subjectIndex = -1;
			this.SubjectTecherIndex = -1;
			this.propertyIndex = index;
		},
		techerTap(index){
			this.TecherIndex = index;
		},
		classTap(index){
			this.classIndex = index;
			// this.getCourseTecher()
		},
		subjectTap(index){
			this.subjectIndex = index;
			this.SubjectTecherIndex = -1;
			this.propertyIndex = -1;
			// this.getSubjectTecherList()
		},
		// // 获取班级
		// getCourseClasss(){
		// 	this.$api.paike.getCourseClasss({id:this.id}).then(res=>{
		// 		if(res.data.code==1){
		// 			this.classList = res.data.data;
		// 			this.getCourseTecher()
		// 		}
		// 	})
		// },
		// getCourseTecher(){
		// 	this.$api.paike.getCourseClasss({
		// 		class_id:this.classList[this.classIndex].id,
		// 		job_id:this.job_id,
		// 	}).then(res=>{
		// 		if(res.data.code==1){
		// 			this.TecherList = res.data.data;
		// 		}
		// 	})
		// },
		// // 获取学科liebiao
		// getSubjectList(){
		// 	this.$api.paike.getSubjectList({
		// 		job_id:this.job_id
		// 	}).then(res=>{
		// 		if(res.data.code==1){
		// 			this.subjectList = res.data.data;
		// 			this.getSubjectTecherList()
		// 		}
		// 	})
		// },
		// // 获取学科下老师
		// getSubjectTecherList(){
		// 	this.$api.paike.getSubjectTecherList({
		// 		job_id:this.job_id,
		// 		job_discipline_id:this.subjectList[this.subjectIndex].job_discipline_id
		// 	}).then(res=>{
		// 		this.SubjectTecherList = res.data.data;
		// 	})
		// },
		// 设置教师进课表
		letin(i, j, k){
			let obj = {};
			if(this.SubjectTecherIndex<0&&this.propertyIndex < 0) return this.$message.error('请选择教师');
			if(this.SubjectTecherIndex!=-1){
				this.info[i].list[j].list[k].teacher_name = this.subjectList[this.subjectIndex].teacher[this.SubjectTecherIndex].teacher_name;
				this.info[i].list[j].list[k].discipline_id = this.subjectList[this.subjectIndex].discipline_id;
				this.info[i].list[j].list[k].discipline_name = this.subjectList[this.subjectIndex].discipline_name;
				this.info[i].list[j].list[k].teacher_id = this.subjectList[this.subjectIndex].teacher[this.SubjectTecherIndex].teacher_id;
				obj.discipline_name = this.subjectList[this.subjectIndex].discipline_name;
				obj.job_discipline_id = this.subjectList[this.subjectIndex].job_discipline_id;
				obj.discipline_type = this.subjectList[this.subjectIndex].discipline_type;
				obj.discipline_id = this.subjectList[this.subjectIndex].discipline_id;
				obj.teacher_id = this.subjectList[this.subjectIndex].teacher[this.SubjectTecherIndex].teacher_id;
				obj.teacher_name = this.subjectList[this.subjectIndex].teacher[this.SubjectTecherIndex].teacher_name;
				obj.attribute = 1;
			}
			if(this.propertyIndex!=-1){
				this.info[i].list[j].list[k].teacher_name = '';
				this.info[i].list[j].list[k].discipline_id = '';
				this.info[i].list[j].list[k].discipline_name = this.property[this.propertyIndex].discipline_name;
				this.info[i].list[j].list[k].teacher_id = '';
				obj.discipline_name = this.property[this.propertyIndex].discipline_name;
				obj.job_discipline_id = '';
				obj.discipline_type = '';
				obj.discipline_id = '';
				obj.teacher_id = '';
				obj.teacher_name = '';
				obj.attribute = 2;
			}
			obj.class_id = this.info[i].class_id;
			obj.class_name = this.info[i].name;
			obj.node = this.info[i].list[j].node;
			obj.node_id = this.info[i].list[j].id;
			obj.start = this.info[i].list[j].start;
			obj.end = this.info[i].list[j].end;
			obj.attend_day = this.info[i].list[j].list[k].attend_day;
			// obj.data = this.info[i].list[j].list[k].data;
			this.subList.push(obj)
			setTimeout(()=>{
				this.$forceUpdate()
			})
		},
		save(s=0){
			console.log(this.subList)
			this.$api.paike.setCourse(this.id,this.subList).then(res=>{
				if(res.data.code==1){
					this.$message.success('保存成功')
					if(s==1){
						// this.changeStatus()
					}else{
						this.initData()
					}
				}else{
					this.$message.error(res.data.msg)
				}
			})
		},
	}
};
</script>

<style lang="scss">
#deploy {
	.caozuo {
		background-color: #ffffff;
		padding: 10px 20px;
		margin-top: 15px;
	}
	.suoding {
		background-color: #f5f6f8;
		border-radius: 2px;
		color: #757c7e;
		margin-left: 15px;
		padding: 5px;
		.suo {
			padding: 0 5px;
			border-right: 1px solid #eaebec;
		}
		.suo_muen {
			padding: 0 8px;
			i {
				font-size: 18px;
			}
		}
	}
	.table-head{
		height: 2.08333333vw;
		min-height: 21.33333333px;
		background-color: #F6F8FB;
		border-bottom: 1px solid #F0F2F5;
		.class_name{
			font-weight: 700;
			height: 100%;
			border-right: 1px solid #F0F2F5;
			width: 4.85416667vw;
			min-width: 39.46666667px;
			color: #68B0FD;
			font-size: 0.729vw;
		}
		.week_name{
			height: 100%;
			border-right: 1px solid #F0F2F5;
			flex: 1;
			min-width: 64px;
			border-right: 1px solid #F0F2F5;
			color: #8B8B8D;
			font-size: 0.7291666666666666vw;
		}
	}
	.table-item{
		height: 2.60416667vw;
		min-height: 26.66666667px;
		border-bottom: 1px solid #F0F2F5;
		.time_name{
			height: 100%;
			background-color: #F6F8FB;
			width: 4.85416667vw;
			min-width: 39.46666667px;
			border-right: 1px solid #F0F2F5;
			color: #8B8B8D;
			font-size: 0.7291666666666666vw;
		}
		.cell{
			position: relative;
			overflow-x: hidden;
			height: 100%;
			border-right: 1px solid #F0F2F5;
			width: 6.25vw;
			flex: 1;
			min-width: 64px;
			cursor: pointer;
			display: -webkit-box;
			display: -ms-flexbox;
			display: flex;
			-webkit-box-pack: center;
			-ms-flex-pack: center;
			justify-content: center;
			-webkit-box-align: center;
			-ms-flex-align: center;
			align-items: center;
			-webkit-box-orient: vertical;
			-webkit-box-direction: normal;
			-ms-flex-direction: column;
			flex-direction: column;
			transition: all .2s ease 0s;
			-moz-transition: all .2s ease 0s;
			-webkit-transition: all .2s ease 0s;
			-o-transition: all .2s ease 0s;
			-ms-transition: all .2s ease 0s;
			p{
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
				width: 4.94791667vw;
				min-width: 50.66666667px;
				margin: 0;
				padding: 0;
				border: 0;
				text-align: center;
				font-size: 0.7291666666666666vw;
			}
			.cell_subject {
			    color: #6F6F6F;
			    margin-bottom: .26041667vw;
			}
			.cell_teacher{
			    color: #A9B8C0;
			}
		}
		.cell:hover{
			background-color: #1890FF;
			.cell_teacher{
				color: #FFFFFF;
			}
			.cell_subject {
				color: #FFFFFF;
			}
		}
	}
	.right_content{
		padding: 0 0 0 20px;
		.controller_item{
			position: relative;
			width: 100%;
			height: 3.74583333vw;
			min-height: 37.33333333px;
			cursor: pointer;
			color: #3D3D3D;
			.iconfont{
				font-size: 1.4583vw;
				color: #BFBFBF;
				margin-bottom: 5px;
			}
		}
		.controller_item:hover{
			background-color: #EEEEEE;
		}
		.right-item{
			background-color: #FAFAFA;
			height: 76vh;
			padding: 10px;
			.right-item-title{
				height: 4.0833vh;
				.line{
					width: 0.1283vw;
					height: 1.483vh;
					background-color: #1890FF;
					margin-right: 8px;
				}
				i{
					color: #BFBFBF;
					margin-left: 4px;
				}
			}
			.subject-list{
				display: flex;
				flex-wrap: wrap;
				.subject-item{
					padding: 5px 15px;
					margin-right: 10px;
					border: 1px solid #EFEFEF;
					border-radius: 2px;
					color: #797979;
					margin-bottom: 10px;
					background-color: #FFFFFF;
					font-size: 0.625vw;
					cursor: pointer;
				}
				.active{
					color: #9C7CEC;
				}
			}
			.teacher-list{
				width: 100%;
				overflow-x: hidden;
				overflow-y: auto;
				-ms-overflow-style: none;
				overflow: '-moz-scrollbars-none';
				scrollbar-width: none;
				-webkit-touch-callout: none;
				-webkit-user-select: none;
				-khtml-user-select: none;
				-moz-user-select: none;
				-ms-user-select: none;
				user-select: none;
				max-height: 13.02083333vw;
				.teacher-item{
					padding: 10px 0;
					cursor: pointer;
					.icon-yuandian1{
						color: #1890FF;
						font-size: 20px;
						margin-right: 3px;
					}
					.teacher-name{
						margin-right: 15px;
					}
					.subject-name{
						color: #98A6B1;
					}
					.num{
						color: #6CAFFD;
					}
				}
				.teacher-item:hover{
					background-color: #EEEEEE;
				}
				.active{
					background-color: #EEEEEE;
				}
			}
		}
	}
}
</style>
